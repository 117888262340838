<template>
  <div>
    <v-autocomplete
      :id="id"
      ref="selectElement"
      v-model="itemsSelected"
      :disabled="disabled"
      class="select-base"
      :class="computedClasses"
      dense
      outlined
      append-icon="mdi-chevron-down"
      multiple
      hide-no-data
      hide-details="auto"
      :label="label"
      :placeholder="placeholderVal"
      :items="itemsSorted"
      :item-text="
        (item) => (![undefined, null, ''].includes(item.groupTitle) ? item.groupTitle : concatenatedText(item))
      "
      :item-value="(item) => item"
    >
      <template v-slot:selection="{ index }">
        <span v-if="index === 0">
          {{ checkSelected(itemsSelected) }}
        </span>
      </template>

      <template v-slot:item="{ item, attrs, on }">
        <v-list-item
          v-bind="attrs"
          :id="'id' + attrs.id"
          #default="{ active }"
          class="item-list"
          :class="itemClassControl(item)"
          @click="onSelectItem(item, attrs)"
          v-on="on"
        >
          <v-list-item-action>
            <v-checkbox :id="item.itemId ? item.itemId.toString() : item.groupTitle" :input-value="active">
            </v-checkbox>
          </v-list-item-action>

          <v-list-item-content :id="attrs.id">
            <v-list-item-title v-if="item.itemTitle" :class="itemTitleClassControl(item)">
              <v-tooltip v-if="item.itemTitle.length > 28" bottom :max-width="250">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    {{ start_and_end(item.itemTitle, 28) }}
                  </span>
                </template>
                {{ item.itemTitle }}
              </v-tooltip>
              <span v-else> {{ item.itemTitle }}</span>
            </v-list-item-title>

            <v-list-item-title v-else>
              <v-tooltip v-if="item.groupTitle.length > 28" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on"> {{ start_and_end(item.groupTitle, 28) }} </span>
                </template>
                {{ item.groupTitle }}
              </v-tooltip>
              <span v-else> {{ item.groupTitle }}</span>
            </v-list-item-title>

            <v-list-item-subtitle v-if="item.itemSubtitle" class="select-item-subtitle">
              {{ item.itemSubtitle }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { uniqueId } from 'lodash';

export default {
  name: 'SelectMultiple',
  props: {
    id: {
      type: String,
      default: uniqueId('_select_multiple'),
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      required: true,
      default: 'Select',
    },

    items: {
      type: Array,
      require: false,
      default: () => {
        return [
          {
            groupTitle: 'Grupo 1',
            groupId: 1,
            items: [
              {
                itemTitle: 'Descrição 1',
                itemSubtitle: 'Subtítulo 1',
                groupId: 1,
                itemId: 1,
              },
            ],
          },
          {
            groupTitle: 'Grupo 2',
            groupId: 2,
            items: [
              {
                itemTitle: 'Descrição 2',
                itemSubtitle: 'Subtítulo 2',
                groupId: 2,
                itemId: 2,
              },
            ],
          },
          {
            itemTitle: 'Item sem grupo',
            itemId: 3,
          },
        ];
      },
    },

    placeholder: {
      required: false,
      default: 'Selecione uma opção',
    },

    value: {
      type: Array,
      default: () => {
        return [];
      },
    },

    hasSelectAll: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      txt: {
        selectAll: 'Selecionar tudo',
      },

      itemsSelected: [],
      placeholderVal: this.placeholder,
    };
  },

  computed: {
    computedClasses() {
      return this.disabled ? 'select-disabled' : 'select-active';
    },

    itemsSorted() {
      var resultItems = [];
      if (this.hasSelectAll) {
        resultItems.push(this.selectAllItemObject);
      }

      this.items.forEach((item) => {
        if (![undefined, null].includes(item.items)) {
          const { items, ...newItem } = item;
          resultItems.push(newItem);
          items.forEach((groupItem) => {
            resultItems.push(groupItem);
          });
        } else {
          resultItems.push(item);
        }
      });
      return resultItems;
    },

    selectAllItemObject() {
      return {
        itemTitle: this.txt.selectAll,
        id: this.id + '_select_all',
        selectAll: true,
      };
    },
  },

  watch: {
    value: function (newValue) {
      this.itemsSelected = newValue;
    },
  },

  methods: {
    checkSelected(itemsSelected) {
      this.clearPlaceholder();

      let totalItems = 0;
      let itemsLength = itemsSelected.length;
      for (let i = 0; i < itemsLength; i++) {
        const item = itemsSelected[i];

        if (item.groupTitle) {
          totalItems++;
        }
      }
      if (totalItems > 0) {
        return `${itemsLength - totalItems} selecionados`;
      } else {
        return `${itemsLength} selecionados`;
      }
    },

    clearPlaceholder() {
      const selectElement = this.$refs.selectElement;
      if (selectElement) {
        this.placeholderVal = this.placeholder;
      }
    },

    concatenatedText(obj) {
      let concatenatedText = '';

      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          if (typeof value === 'string') {
            concatenatedText += value;
          }
          if (typeof value === 'number') {
            concatenatedText += value.toString();
          }
        }
      }
      return concatenatedText.toLowerCase();
    },

    start_and_end(str, lengthStr) {
      if (str.length > lengthStr) {
        return str.substr(0, lengthStr) + '...';
      }
      return str;
    },

    removeText(textForRemove, str) {
      return str.replace(new RegExp(textForRemove, 'g'), '');
    },

    onSelectItem(item, value) {
      this.$nextTick(() => {
        var valueInputed = !value.inputValue;

        if (this.isSelectAll(item)) {
          this.toggleAll(valueInputed);
        } else if (this.isGroupTitle(item)) {
          this.toggleSelectAllGroup(item, valueInputed);
        }

        this.verifyGroupNodeStatus(item);
        if (this.hasSelectAll) {
          this.verifySelectAllNodeStatus(valueInputed);
        }
        this.$emit('input', this.itemsSelected);
        this.$emit('onSelect', { item: item, checked: valueInputed });
      });
    },

    itemTitleClassControl(item) {
      let classes = '';
      if (![undefined, null].includes(item.groupId)) {
        classes += 'select-group-item';
        return classes;
      }
      return classes;
    },

    itemClassControl(item) {
      let classes = '';
      if (this.hasSelectAll) {
        classes += this.isSelectAll(item) ? 'select-all-item' : 'select-item';
      }
      classes += this.isGroupTitle(item) ? ' select-group-title' : '';
      if (this.isGroupItem(item)) {
        classes += this.hasSubtitle(item) ? ' select-group-item-title' : ' select-group-item';
      }

      return classes;
    },

    isSelectAll(item) {
      return this.hasSelectAll && ![undefined, null].includes(item.selectAll);
    },

    isGroupTitle(item) {
      return ![undefined, null].includes(item.groupTitle);
    },

    hasGroupId(item) {
      return ![undefined, null].includes(item.groupId);
    },

    isGroupItem(item) {
      return !this.isGroupTitle(item) && this.hasGroupId(item);
    },

    hasSubtitle(item) {
      return ![undefined, null].includes(item.itemSubtitle);
    },

    toggleAll(value) {
      if (value) {
        this.includeItemsToSelectedList(this.itemsSorted);
      } else {
        this.itemsSelected = [];
      }
    },

    toggleSelectAllGroup(item, value) {
      let groupId = item.groupId;
      let groupItems = this.itemsSorted.filter((item) => {
        return item.groupId === groupId;
      });
      value ? this.includeItemsToSelectedList(groupItems) : this.removeItemsFromSelectedList(groupItems);
    },

    includeItemsToSelectedList(items) {
      items.forEach((item) => {
        let hasItem = false;
        for (var i = this.itemsSelected.length - 1; i >= 0; i--) {
          if (JSON.stringify(item) === JSON.stringify(this.itemsSelected[i])) {
            hasItem = true;
          }
        }
        if (!hasItem) this.itemsSelected.push(item);
      });
    },

    removeItemsFromSelectedList(items) {
      for (var i = this.itemsSelected.length - 1; i >= 0; i--) {
        items.forEach((item) => {
          if (JSON.stringify(item) === JSON.stringify(this.itemsSelected[i])) {
            this.itemsSelected.splice(i, 1);
          }
        });
      }
    },

    verifyGroupNodeStatus(item) {
      let groupNode = {};

      if (![undefined, null].includes(item.groupId)) {
        let groupItems = this.itemsSorted.filter((groupItem) => {
          if (![undefined, null].includes(groupItem.groupId)) {
            return groupItem.groupId === item.groupId;
          }
          return false;
        });

        groupNode = groupItems.splice(0, 1);

        const everyInGroupIsSelected = (groupItem) => {
          let hasItem = false;
          this.itemsSelected.forEach((selectedItem) => {
            if (!hasItem) {
              hasItem = JSON.stringify(selectedItem) === JSON.stringify(groupItem);
            }
          });
          return hasItem;
        };
        groupItems.every(everyInGroupIsSelected)
          ? this.includeItemsToSelectedList(groupNode)
          : this.removeItemsFromSelectedList(groupNode);
      }
    },

    verifySelectAllNodeStatus(value) {
      if (value) {
        if (this.itemsSorted.length - 1 === this.itemsSelected.length) {
          this.includeItemsToSelectedList([this.selectAllItemObject]);
        }
      } else {
        this.removeItemsFromSelectedList([this.selectAllItemObject]);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import './src/design/variables.scss';
@import './src/design/components/input.scss';
@import './src/design/components/select.scss';

.select-group-title {
  background-color: $neutral-color-low-medium;
  color: $neutral-color-high-dark;
  .v-list-item__action {
    margin-left: 0 !important;
  }
  span {
    font-style: normal;
    font-size: $font-size-xs;
    font-weight: $font-weight-regular;
  }
}

.select-item {
  .v-list-item__action {
    margin-left: $spacing-05;
  }
}

.select-group-item-title {
  color: $neutral-color-high-dark;
  .v-list-item__action {
    margin-left: $spacing-05;
  }
  span {
    font-style: normal;
    color: $neutral-color-high-dark;
    font-weight: $font-weight-bold !important;
    font-size: $font-size-xxs;
  }
}

.select-all-item {
  background-color: $neutral-color-low-medium;
}

.select-group-item {
  color: $neutral-color-high-dark;
  .v-list-item__action {
    margin-left: $spacing-05;
  }
  span {
    font-style: normal;
    color: $neutral-color-high-dark;
    font-weight: $font-weight-regular;
    font-size: $font-size-xxs;
  }
}

::v-deep {
  .v-select__selections {
    span {
      margin-right: $spacing-01;
    }
  }

  .v-list-item__action {
    margin-right: 0 !important;
  }

  .v-list-item__title {
    color: $neutral-color-high-dark;
  }

  .v-input--selection-controls__input {
    i {
      color: $brand-color-primary-medium;
    }
  }
}
</style>
