var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-autocomplete", {
        ref: "selectElement",
        staticClass: "select-base",
        class: _vm.computedClasses,
        attrs: {
          id: _vm.id,
          disabled: _vm.disabled,
          dense: "",
          outlined: "",
          "append-icon": "mdi-chevron-down",
          multiple: "",
          "hide-no-data": "",
          "hide-details": "auto",
          label: _vm.label,
          placeholder: _vm.placeholderVal,
          items: _vm.itemsSorted,
          "item-text": (item) =>
            ![undefined, null, ""].includes(item.groupTitle)
              ? item.groupTitle
              : _vm.concatenatedText(item),
          "item-value": (item) => item,
        },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function ({ index }) {
              return [
                index === 0
                  ? _c("span", [
                      _vm._v(
                        " " + _vm._s(_vm.checkSelected(_vm.itemsSelected)) + " "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "item",
            fn: function ({ item, attrs, on }) {
              return [
                _c(
                  "v-list-item",
                  _vm._g(
                    _vm._b(
                      {
                        staticClass: "item-list",
                        class: _vm.itemClassControl(item),
                        attrs: { id: "id" + attrs.id },
                        on: {
                          click: function ($event) {
                            return _vm.onSelectItem(item, attrs)
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ active }) {
                                return [
                                  _c(
                                    "v-list-item-action",
                                    [
                                      _c("v-checkbox", {
                                        attrs: {
                                          id: item.itemId
                                            ? item.itemId.toString()
                                            : item.groupTitle,
                                          "input-value": active,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    { attrs: { id: attrs.id } },
                                    [
                                      item.itemTitle
                                        ? _c(
                                            "v-list-item-title",
                                            {
                                              class:
                                                _vm.itemTitleClassControl(item),
                                            },
                                            [
                                              item.itemTitle.length > 28
                                                ? _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: {
                                                        bottom: "",
                                                        "max-width": 250,
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                              attrs,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "span",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {},
                                                                      "span",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.start_and_end(
                                                                            item.itemTitle,
                                                                            28
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.itemTitle
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.itemTitle)
                                                    ),
                                                  ]),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "v-list-item-title",
                                            [
                                              item.groupTitle.length > 28
                                                ? _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { bottom: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                              attrs,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "span",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {},
                                                                      "span",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.start_and_end(
                                                                            item.groupTitle,
                                                                            28
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.groupTitle
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.groupTitle)
                                                    ),
                                                  ]),
                                            ],
                                            1
                                          ),
                                      item.itemSubtitle
                                        ? _c(
                                            "v-list-item-subtitle",
                                            {
                                              staticClass:
                                                "select-item-subtitle",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.itemSubtitle) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      "v-list-item",
                      attrs,
                      false
                    ),
                    on
                  )
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.itemsSelected,
          callback: function ($$v) {
            _vm.itemsSelected = $$v
          },
          expression: "itemsSelected",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }